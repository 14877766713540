import React from 'react';
import {
  Container,
  Grid,
  Typography,
} from '@material-ui/core'
import { Link, graphql } from 'gatsby'
import Layout from "../../components/layout"
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { createContent } from "../../common/Theme"
import FadeIn from "../../components/FadeIn"
import ProjectsLink from "../../components/projectsLink"
import ContactUs from "../../components/contactUs"

import '../../assets/styles/pages/services.scss'

const DesignPage = ({ data: { allMarkdownRemark: { edges } } }) => {
  const content = createContent();

  return (
    <Layout>
      <section className='services-hero-section' id='design-hero-section'>
        <Container classes={{root: content.content}}>
          <div className='services-hero-container'>
            <div className='services-hero-title'>
              <Typography className='text-tag' variant='body2' color='textSecondary'>What we do</Typography>
              <Typography variant='h1' color='textSecondary'>Design</Typography>
            </div>
            <div className='services-hero-image' id='design-hero-image' />
          </div>
        </Container>
      </section>
      <section>
        <Container classes={{root: content.content}}>
          <Grid container spacing={10}>
            <Grid item md={6} sm={6} xs={12}>
              <FadeIn>
                <Typography variant='h3'>Mobile-first Web Design</Typography>
                <Typography>
                  We design and develop websites with a mobile-first and user-centric approach that work
                  seamlessly across all browsers and devices.
                  From desktop screens to mobile phones and tablets, we build websites that scale
                  and adapt smoothly and that create meaningful interactions with the user.
                </Typography>
              </FadeIn>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <FadeIn>
                <Typography variant='h3'>UI / UX Design</Typography>
                <Typography>
                  An effective user interface and user experience is vital for the success of a website or application.
                  We help in crafting user interfaces that are both appealing and intuitive.
                  To ensure the engagement of your users we look for the optimal way to lay out interactions
                  that result in a great experience.
                </Typography>
              </FadeIn>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <FadeIn>
                <Typography variant='h3'>Wireframing & Prototyping</Typography>
                <Typography>
                  Wireframes are the visual guides that represent the skeletal structure of a website or app.
                  Prototyping tests specific interactions with that visual representation.
                  By creating wireframes and prototypes we can test the effectiveness of the website or app
                  and ensure maximum usability of the final product.
                </Typography>
              </FadeIn>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section id='related-cases-section'>
        <Container classes={{root: content.content}}>
          <Typography variant='h3' color='textSecondary'>Related projects</Typography>
          <Grid container spacing={6}>
            {
              edges.map(edge => (
                <Grid item md={6} key={edge.node.id}>
                  <ProjectsLink
                    slug={edge.node.frontmatter.slug}
                    title={edge.node.frontmatter.title}
                    image={edge.node.frontmatter.featuredImage}
                    categories={edge.node.frontmatter.categories}
                    light
                  />
                </Grid>
              ))
            }
          </Grid>
          <Typography variant='h3' color='textSecondary'>Other services</Typography>
          <Link to='/services/development' className='other-services-link'>
            <span>Development</span>
            <ArrowRightAltIcon />
          </Link>
          <Link to='/services/consulting' className='other-services-link'>
            <span>Consulting</span>
            <ArrowRightAltIcon />
          </Link>
        </Container>
      </section>
      <ContactUs mt />
    </Layout>
  )
}

export default DesignPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { slug: { in: ["/projects/rt112", "/projects/gvb"] } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            slug
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
